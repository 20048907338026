import { serviceHandler } from '../utils/serviceHandler';
import config from '../config';
import { convertToStandardTreeFormat, parseDMS } from '../utils';

const {
  getVessels, uploadVesselPlan, vesselDetails, getVesselPlans, getDefectList, getDefectStatusList,
  removeVesselPlan, exportVesselList, getVoyageReports, getVoyageReportTypes,
  getVesselPlanCategories, getVesselInpsectionList, exportVesselInspection, listAudits,
  uploadAccounting, getAccountingTypes, listAccoutings, exportAudit, listBunkerings,
  listBunkeringSurverys, deleteAccounting, exportVesselReports, addDocumentTypes,
  editDocumentTypes, deleteDocumentType, getCrewList, getVesselLocations, exportCrewList,
  getVoyageReportNoPaging, getCertificateList, getCertificateHistory, getCertificateSurveys,
  getCrewCount, getCertificateCount, getEnginePerformance, listCertificateDeviations,
  listOpenCoc, listPurchaseOrders, listPmsJobs, listPmsDepartments, listPmsStatus, listSpareReport,
  listVesselManagement, listPmsHistory, getVesselTypes, exportDefectDetails,
  exportPmsHistoryDetails, exportNearMissReport, exportAccidentReport, exportShipnetDefectReport,
  exportFreeFlowReport, updateVesselV3Status, getPurchaseDetails, getPurchaseLog,
  getPurchaseItemsList, uploadSupportingDocs, getFileDownload, exportPmsList, getMessage,
  getPmsClass, exportVesselListexcel, getRobList, getConsumptionList, getVesselExpenseList,
  getVesselExpenseDetails, getCumulativeExpense, exportcrewExcel, exportvesselExpense,
  exportvesseExpenseDetails, getfilesizeLimit, getdefectReportType, getdefectauditType,
  getdefectReportlist, getDefectSubType, exportPurchase, exportCertificates, getAisVesselLocation,
  getPmsComponents, exportSparesReport, getVesselVoyageDetail, exportVesselDetailReports,
  getPmsSpareSubClass, exportVesselParticularList
} = config.routes;

export const getVesselsGET = (payload) => {
  return serviceHandler({
    url: getVessels,
    methodType: 'post',
    payload
  });
};

export const uploadVesselPlanPOST = (payload) => {
  return serviceHandler({
    url: uploadVesselPlan,
    methodType: 'post',
    payload
  });
};

export const getVesselDetailsGET = (payload) => {
  return serviceHandler({
    url: vesselDetails,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getVesselPlansPOST = (payload) => {
  return serviceHandler({
    url: getVesselPlans,
    methodType: 'post',
    payload
  });
};

export const getDefectListPOST = (payload) => {
  return serviceHandler({
    url: getDefectList,
    methodType: 'post',
    payload
  });
};

export const getDefectStatusListGET = (payload) => {
  return serviceHandler({
    url: getDefectStatusList,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const removeVesselPlanPOST = (payload) => {
  return serviceHandler({
    url: `${removeVesselPlan}/${payload.id}`,
    methodType: 'delete'
  });
};

export const exportVesselListPOST = (payload) => {
  return serviceHandler({
    url: exportVesselList,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getVoyageReportsPOST = (payload) => {
  return serviceHandler({
    url: getVoyageReports,
    methodType: 'post',
    payload
  });
};

export const getVoyageReportTypesGET = (payload) => {
  return serviceHandler({
    url: getVoyageReportTypes,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getVesselPlanCategoriesGET = (payload) => {
  return serviceHandler({
    url: getVesselPlanCategories,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getVesselInpsectionListPOST = (payload) => {
  return serviceHandler({
    url: getVesselInpsectionList,
    methodType: 'post',
    payload
  });
};

export const exportVesselInspectionPOST = (payload) => {
  return serviceHandler({
    url: exportVesselInspection,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const listAudtisPOST = (payload) => {
  return serviceHandler({
    url: listAudits,
    methodType: 'post',
    payload
  });
};

export const listAccountingPOST = (payload) => {
  return serviceHandler({
    url: listAccoutings,
    methodType: 'post',
    payload
  });
};

export const uploadAccountingPOST = (payload) => {
  return serviceHandler({
    url: uploadAccounting,
    methodType: 'post',
    payload
  });
};

export const getAccountingTypesGET = (payload) => {
  return serviceHandler({
    url: getAccountingTypes,
    methodType: 'post',
    payload
  });
};

export const exportAuditPOST = (payload) => {
  return serviceHandler({
    url: exportAudit,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const deleteAccountingDELETE = (payload) => {
  return serviceHandler({
    url: `${deleteAccounting}/${payload.id}`,
    methodType: 'delete'
  });
};

export const listBunkeringsPOST = (payload) => {
  return serviceHandler({
    url: listBunkerings,
    methodType: 'post',
    payload
  });
};

export const exportBunkeringsPOST = () => {
  return Promise.resolve();
};

export const listBunkeringSurveysPOST = (payload) => {
  return serviceHandler({
    url: listBunkeringSurverys,
    methodType: 'post',
    payload
  });
};

export const exportVesselReportsPOST = (payload) => {
  return serviceHandler({
    url: exportVesselReports,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const deleteAccountingTypeDELETE = (payload) => {
  return serviceHandler({
    url: deleteDocumentType,
    methodType: 'delete',
    payload: { params: payload }
  });
};

export const addAccountingTypePOST = (payload) => {
  return serviceHandler({
    url: addDocumentTypes,
    methodType: 'post',
    payload
  });
};

export const editAccountingTypePUT = (payload) => {
  return serviceHandler({
    url: editDocumentTypes,
    methodType: 'put',
    payload
  });
};

export const getCrewListPOST = (payload) => {
  return serviceHandler({
    url: getCrewList,
    methodType: 'post',
    payload
  });
};

export const getVesselLocationsPOST = async (payload) => {
  const res = await serviceHandler({
    url: getVesselLocations,
    methodType: 'post',
    payload
  });
  if (res?.data?.length) {
    const data = res.data.map((item) => {
      let position = null;
      if (item.latitude && item.longitude) {
        position = parseDMS(item.latitude, item.longitude);
      }
      return { ...item, position };
    });
    return { ...res, data };
  }
  return res;
};

export const exportCrewListPOST = (payload) => {
  return serviceHandler({
    url: exportCrewList,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const listCertificatesPOST = (payload) => {
  return serviceHandler({
    url: getCertificateList,
    methodType: 'post',
    payload
  });
};

export const listCertificateHistoryPOST = (payload) => {
  return serviceHandler({
    url: getCertificateHistory,
    methodType: 'post',
    payload
  });
};

export const getVoyageReportNoPagingPOST = (payload) => {
  return serviceHandler({
    url: getVoyageReportNoPaging,
    methodType: 'post',
    payload
  });
};

export const getCertificateSurveysPOST = (payload) => {
  return serviceHandler({
    url: getCertificateSurveys,
    methodType: 'post',
    payload
  });
};

export const getCrewCountPOST = (payload) => {
  return serviceHandler({
    url: getCrewCount,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getCertificateCountGET = (payload) => {
  return serviceHandler({
    url: getCertificateCount,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getEnginePerformancePOST = (payload) => {
  return serviceHandler({
    url: getEnginePerformance,
    methodType: 'post',
    payload
  });
};

export const listCertificateDeviationsPOST = (payload) => {
  return serviceHandler({
    url: listCertificateDeviations,
    methodType: 'post',
    payload
  });
};

export const listOpenCocPOST = (payload) => {
  return serviceHandler({
    url: listOpenCoc,
    methodType: 'post',
    payload
  });
};

export const listPurchaseOrdersPOST = (payload) => {
  return serviceHandler({
    url: listPurchaseOrders,
    methodType: 'post',
    payload
  });
};

export const listPmsJobsPOST = (payload) => {
  return serviceHandler({
    url: listPmsJobs,
    methodType: 'post',
    payload
  });
};

export const listPmsDepartmentsGET = (payload) => {
  return serviceHandler({
    url: listPmsDepartments,
    methodType: 'get',
    payload
  });
};

export const listPmsStausGET = (payload) => {
  return serviceHandler({
    url: listPmsStatus,
    methodType: 'get',
    payload
  });
};

export const listSpareReportPOST = (payload) => {
  return serviceHandler({
    url: listSpareReport,
    methodType: 'post',
    payload
  });
};

export const listVesselManagementGET = (payload) => {
  return serviceHandler({
    url: listVesselManagement,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const listPmsHistoryPOST = (payload) => {
  return serviceHandler({
    url: listPmsHistory,
    methodType: 'post',
    payload
  });
};

export const getVesselTypesGET = (payload) => {
  return serviceHandler({
    url: getVesselTypes,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const exportDefectDetailsPOST = (payload) => {
  return serviceHandler({
    url: exportDefectDetails,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportPmsHistoryDetailsPOST = (payload) => {
  return serviceHandler({
    url: exportPmsHistoryDetails,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportNearMissReportPOST = (payload) => {
  return serviceHandler({
    url: exportNearMissReport,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportAccidentReportPOST = (payload) => {
  return serviceHandler({
    url: exportAccidentReport,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportShipnetDefectReportPOST = (payload) => {
  return serviceHandler({
    url: exportShipnetDefectReport,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportFreeFlowReportPOST = (payload) => {
  return serviceHandler({
    url: exportFreeFlowReport,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const updateVesselV3StatusPUT = (payload) => {
  return serviceHandler({
    url: updateVesselV3Status,
    methodType: 'put',
    payload
  });
};

export const getPurchaseDetailsPOST = (payload) => {
  return serviceHandler({
    url: getPurchaseDetails,
    methodType: 'post',
    payload
  });
};

export const getPurchaseLogPOST = (payload) => {
  return serviceHandler({
    url: getPurchaseLog,
    methodType: 'post',
    payload
  });
};

export const getPurchaseItemsListPOST = (payload) => {
  return serviceHandler({
    url: getPurchaseItemsList,
    methodType: 'post',
    payload
  });
};

export const uploadSupportingDocPOST = (payload) => {
  return serviceHandler({
    url: uploadSupportingDocs,
    methodType: 'post',
    payload
  });
};

export const getFileDownloadGET = (payload) => {
  const { documentIdList = [] } = payload;
  const param = documentIdList.reduce((acc, curr, index) => {
    return `${acc}documentIdList=${curr}${index < documentIdList.length - 1 ? '&' : ''}`;
  }, '');

  return serviceHandler({
    url: `${getFileDownload}?${param}`,
    methodType: 'get',
    options: { responseType: 'blob' }
  });
};

export const exportPmsListPOST = (payload) => {
  return serviceHandler({
    url: exportPmsList,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getMessageGET = (payload) => {
  return serviceHandler({
    url: getMessage,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getPmsClassGET = (payload) => {
  return serviceHandler({
    url: getPmsClass,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const exportVesselListExcelPOST = (payload) => {
  return serviceHandler({
    url: exportVesselListexcel,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getRobListGET = (payload) => {
  return serviceHandler({
    url: getRobList,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getConsumptionListGET = (payload) => {
  return serviceHandler({
    url: getConsumptionList,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getVesselExpenseListPOST = (payload) => {
  return serviceHandler({
    url: getVesselExpenseList,
    methodType: 'post',
    payload
  });
};

export const getVesselExpenseDetailsPOST = (payload) => {
  return serviceHandler({
    url: getVesselExpenseDetails,
    methodType: 'post',
    payload
  });
};

export const getCumulativeExpenseDetailsPOST = (payload) => {
  return serviceHandler({
    url: getCumulativeExpense,
    methodType: 'post',
    payload
  });
};

export const exportExcelCrewListPOST = (payload) => {
  return serviceHandler({
    url: exportcrewExcel,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportVesselExpensePOST = (payload) => {
  return serviceHandler({
    url: exportvesselExpense,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportVesselExpenseDetailsPOST = (payload) => {
  return serviceHandler({
    url: exportvesseExpenseDetails,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getFileSizeGET = (payload) => {
  return serviceHandler({
    url: getfilesizeLimit,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getDefectReportGET = (payload) => {
  return serviceHandler({
    url: getdefectReportType,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const getDefectAuditTypePOST = (payload) => {
  return serviceHandler({
    url: getdefectauditType,
    methodType: 'post',
    payload
  });
};

export const getDefectReportListPOST = (payload) => {
  return serviceHandler({
    url: getdefectReportlist,
    methodType: 'post',
    payload
  });
};

export const getDefectReportSubTypeListPOST = (payload) => {
  return serviceHandler({
    url: getDefectSubType,
    methodType: 'post',
    payload
  });
};

export const exportPurchasePOST = (payload) => {
  return serviceHandler({
    url: exportPurchase,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const exportCertificatePOST = (payload) => {
  return serviceHandler({
    url: exportCertificates,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getAisVesselLocationPOST = async (payload) => {
  const res = await serviceHandler({
    url: getAisVesselLocation,
    methodType: 'post',
    payload
  });
  if (res?.data?.length) {
    const data = res.data.map((item) => {
      let position = null;
      if (item.latitude && item.longitude) {
        position = parseDMS(item.latitude, item.longitude);
      }
      return { ...item, position };
    });
    return { ...res, data };
  }
  return res;
};

export const getPmsComponentsGET = (payload) => {
  return serviceHandler({
    url: getPmsComponents,
    methodType: 'get',
    payload: { params: payload }
  }).then((res) => {
    let data = [];
    if (res?.data) {
      data = convertToStandardTreeFormat(res.data.response, 'name', 'code', 'children');
    }
    return { data };
  });
};

export const exportSparesReportPOST = (payload) => {
  return serviceHandler({
    url: exportSparesReport,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getVesselVoyageDetailPOST = (payload) => {
  return serviceHandler({
    url: getVesselVoyageDetail,
    methodType: 'post',
    payload
  });
};

export const exportVesselDetailReportsPOST = (payload) => {
  return serviceHandler({
    url: exportVesselDetailReports,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};

export const getPmsSubClassGET = (payload) => {
  return serviceHandler({
    url: getPmsSpareSubClass,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const exportVesselParticularPOST = (payload) => {
  return serviceHandler({
    url: exportVesselParticularList,
    methodType: 'post',
    payload,
    options: { responseType: 'blob' }
  });
};
